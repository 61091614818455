import { bindable } from 'aurelia-templating';

export class Spinner {
    /**
     * Shows the provided spinner
     * @param spinner The spinner to show
     */
    public static show(spinner: Spinner): void {
        if (spinner) spinner.show();
    }

    /**
     * Shows the provided spinners
     * @param spinners The spinners to show
     * The spinners object format is expected to be like so:
     * {
     *     spinner1Name: spinner1Element,
     *     spinner2Name: spinner2Element
     * }
     */
    public static showMultiple(spinners: any): void {
        for (const spinner in spinners)
            if (spinners[spinner])
                spinners[spinner].show();
    }

    /**
     * Hides the provided throbbber
     * @param spinner The spinner to hide
     */
    public static hide(spinner: Spinner): void {
        if (spinner) spinner.hide();
    }

    /**
     * Hides the provided spinners
     * @param spinners The spinners to hide
     * The spinners object format is expected to be like so:
     * {
     *     spinner1Name: spinner1Element,
     *     spinner2Name: spinner2Element
     * }
     */
    public static hideMultiple(spinners: any): void {
        for (const spinner in spinners)
            if (spinners[spinner])
                spinners[spinner].hide();
    }

    /**
     * Shows a spinner by its HTML element's id
     * @param elementId Id of the spinner HTML element to show
     */
    public static showByElementId(elementId: string): void {
        const element = document.getElementById(elementId) as any;
        if (element)
            Spinner.show(element.au.controller.viewModel);
    }

    /**
     * Hides a spinner by its HTML element's id
     * @param elementId Id of the spinner HTML element to hide
     */
    public static hideByElementId(elementId: string): void {
        const element = document.getElementById(elementId) as any;
        if (element)
            Spinner.hide(element.au.controller.viewModel);
    }

    /**
     * Whether or not spinner should be shown
     */
    @bindable() public visible: boolean = true;

    /**
     * Adjusts the spinner's size, accepts all FontAwesome sizes like xs, sm, lg, 2x, 3x...
     */
    @bindable() private size: string;

    /**
     * Adjusts the spinner's display style, accepts all FontAwesome styles like fas, far, fal
     */
    @bindable() private display: 'fas' | 'far' | 'fal' = 'fal';

    /**
     * Adjusts the spinner's icon, accepts all FontAwesome icons
     */
    @bindable() private icon: string = 'spinner-third';

    /**
     * Whether or not the icon should be spinning
     */
    @bindable() private spin: boolean = true;

    /**
     * A collection of generic loading messages.
     * One of these will be picked at random as the spinner's message.
     */
    private messages: string[] = [
        'Getting things ready...',
        'Getting ready...',
        'Preparing things...',
        'Preparing...',
        'Loading things...',
        'Loading...',
        'Thinking...',
        'Retrieving things...',
        'Retrieving...',
        'Readying things...',
        'Readying...'
    ];

    /**
     * Random message picked from the messages array.
     * Can be overriden by binding a value to message. Overriding this will set showMessage to true so the custom
     * message will always be displayed with the spinner.
     */
    @bindable() private message: string = this.messages[Math.floor(Math.random() * this.messages.length)];

    /**
     * Controls whether or not a loading message should be displayed.
     */
    @bindable() private showMessage: boolean = false;

    /**
     * Controls if the message should be in line with the spinner.
     * Defaults to false, meaning the message will be displayed below the spinner.
     */
    @bindable() private inline: boolean = false;

    /**
     * Hides the spinner
     */
    public hide(): void {
        this.visible = false;
    }

    /**
     * Shows the spinner
     */
    public show(): void {
        this.visible = true;
    }

    /**
     * Handles the event where the message property changes.
     * Sets showMessage property to true, showing the custom set message with the spinner.
     */
    private messageChanged(): void {
        this.showMessage = true;
    }
}
