import { bindable, bindingMode, children, customElement } from 'aurelia-framework';
import { Value } from '../../infra/value';
import { UxSelectOption } from '../select-option/select-option';
import { Guid } from '../../infra/guid';

@customElement('ux-multi-select')
@children({ name: 'options', selector: 'ux-select-option' })
export class UxMultiSelect {

    @bindable() public values: any[];
    @bindable() public label: string;
    @bindable() public placeholder: string = 'placeholder';
    @bindable() private onSelect: (selectedValues: any[], option: any) => void;
    @bindable() private disableClear: boolean = false;
    @bindable() private disabled: boolean = false;
    @bindable() private valid: boolean;

    private options: UxSelectOption[];
    private visible: boolean = false;
    private isLoading: boolean = true;
    private display: HTMLElement;
    private topPosition: number;
    private guid: string = Guid.newGuid();

    public show(): void {
        if (this.disabled) return;

        const position = this.display.getBoundingClientRect();
        this.topPosition = position.height - 1;

        this.visible = true;
    }

    public hide(): void {
        this.visible = false;
    }

    public setValues(values: any[]): void {
        // Create a new array so the reference
        // doesn't exist.
        this.values = [].concat(values);
        this.renderOptions();
    }

    private attached(): void {
        document.getElementsByTagName('body')[0].addEventListener('click', (e) => this.hideOnClickOutside(e));
    }

    private detached(): void {
        document.getElementsByTagName('body')[0].removeEventListener('click', (e) => this.hideOnClickOutside(e));
    }

    private hideOnClickOutside(event: any): void {
        const parent = event.target.closest(`#ux-multi-select-${this.guid}`);
        if (Value.doesNotExist(parent)) this.hide();

        // if (!event.target.closest('.ux-multi-select-options-container')) this.hide();
    }

    private toggleVisibility(event: any): void {
        if (this.disabled) return;
        event.stopPropagation();

        const position = this.display.getBoundingClientRect();
        this.topPosition = position.height - 1;

        this.visible = !this.visible;
    }

    private select(event: any, option: UxSelectOption): void {
        if (this.disabled) return;

        // Prevent, when clicking on the clear button, selecting an option
        event.stopPropagation();
        if (option) {
            if (!this.values) this.values = [];

            if (this.values.some((x) => x === option.value)) this.values = this.values.filter((x) => x !== option.value);
            else this.values.push(option.value);

            if (this.values.length === 0) this.values = null;
        } else this.values = null;

        if (this.onSelect) this.onSelect(this.values, option);

        this.renderOptions();

        setTimeout(() => {
            const position = this.display.getBoundingClientRect();
            this.topPosition = position.height - 1;
        });
    }

    private optionsChanged(): void {
        this.isLoading = false;
    }

    private isSelected(option: UxSelectOption): boolean {
        if (!this.values) return false;
        return this.values.some((x) => x === option.value);
    }

    private async handleRemoveItem(event: any, value: any): Promise<void> {
        event.stopPropagation();

        const index = this.values.findIndex((x) => x === value);
        this.values.splice(index, 1);

        // Force the values to re-render
        this.values = Object.assign([], this.values);
        if (Value.isFunction(this.onSelect)) await this.onSelect(this.values, null);

        this.renderOptions();
    }

    private getLabel(value: string | { name: string }): string {
        // Ignore function if we don't have a value
        if (value === null || value === undefined) return;
        // Try to find the matching option
        // If Value has a name property use that instead.
        let option = undefined;
        if (typeof value === 'object' && value.name)
            option = this.options.find((x: UxSelectOption) => x.value.name?.toString() === value.name.toString());
        else
            option = this.options.find((x: UxSelectOption) => x.value.toString() === value.toString());

        // If we don't have a matching option we assume the value is not available
        // in the options array. Reset the value and ignore the rest of the function.
        if (option === null || option === undefined) return value.toString();
        return option.label;
    }

    private renderOptions(): void {
        // Dirty way to force the options to render again
        // this way we can mark the just selected option.
        this.options = [this.options.shift()].concat(this.options);
    }
}
