import { autoinject, bindable, containerless, observable } from 'aurelia-framework';

@autoinject()
@containerless()
export class ListView {
    @bindable public title: string;
    @bindable public description: string;
    @bindable public addButtonText: string;
    @bindable public addFieldPlaceholder: string;
    @bindable() public addValue: (args: { value: string }) => Promise<boolean>;
    @bindable() public validate: (args: { value: string }) => Promise<void>;
    @bindable public loading: boolean;
    @bindable public validation: ListViewValidation[] | undefined;
    @observable private inputValue: string = undefined;
    private addingValue: boolean = false;

    private textInput: HTMLInputElement;

    private addTheValue(): void {
        this.addingValue = true;
        this.addValue({ value: this.inputValue }).then((success) => {
            if (success) {
                this.inputValue = undefined;
            }
        }).finally(() => {
            this.addingValue = false;
        });
    }

    public resetValidation(): void {
        this.validation = undefined;
    }

    private onKeyDown(event: KeyboardEvent): boolean {
        if (event.key === 'Enter') {
            this.addTheValue();
            return false;
        }

        return true;
    }

    private inputValueChanged(): boolean {
        // When there is no input value, it's not necessary to validate the change
        if (this.inputValue === undefined || this.inputValue === null || this.inputValue === '')
            return false;

        if (this.validate)
            this.validate({ value: this.inputValue }).then((_) => {
                return true;
            });
        return true;
    }
}

export class ListViewValidation {
    message: string;
    type: 'error' | 'warning' | 'success' | 'info';
}
