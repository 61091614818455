import * as signalr from '@aspnet/signalr';
import { AureliaConfiguration } from 'aurelia-configuration';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AuthenticationProvider } from 'providers/authentication-provider';
import { StateApi } from 'services/state-api';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import { EventKeys } from 'enums/event-keys';
import { EnrichedNotification, EventTypes, INotification } from 'services/cyber-api';

@autoinject()
export class NotificationReceiver {

    private connection: signalr.HubConnection;
    private readonly genericTopic: string = 'GenericBroadcastTopic';
    private readonly cyberNotificationTopic: string = 'CyberNotificationTopic';

    constructor(
        private auth: AuthenticationProvider,
        private settings: AureliaConfiguration,
        private ea: EventAggregator,
        private router: Router,
        private state: StateApi) {
    }

    /** Subscribes to company notifications (e.g. 'Group Notifications'). */
    public async subscribe(): Promise<void> {
        if (this.connection)
            await this.connection.invoke('subscribe', this.state.company());
    }

    /** Unsubscribes from company notifications. */
    public async unsubscribe(): Promise<void> {
        if (this.connection)
            await this.connection.invoke('unsubscribe', this.state.company());
    }

    public showNotification(notification: EnrichedNotification): void {
        // Threat detected
        if (notification.triggered_rule.includes('__CyberNotificationTopic__EventRule__alert__alert_created')) {
            // In which zone the alert was triggered. The event doesn't always contain the zone_name property, so this
            // only adds the zone name to the notification if it's available.
            const occurredInZoneName = notification.attributes.zone_name
                ? ' in ' + notification.attributes.zone_name
                : '';
            const message = `New threat detected<br>
                             <small>onboard ${notification.vesselName || notification.attributes.vessel_name || 'N/A'}${occurredInZoneName}</small>`;
            Toast.threat(message, '', {
                onclick: (event) => this.router.navigateToRoute('threats', { id: notification.threatShortId })
            });

            // Threat detected in category
        } else if (notification.triggered_rule.includes('__CyberNotificationTopic__CompositeRule__alert__alert_created|absolute__$.alert_type.category')) {
            const message = `New ${notification.threatCategory} threat detected<br>
                             <small>onboard ${notification.vesselName || notification.attributes.vessel_name || 'N/A'} in ${notification.attributes.zone_name}</small>`;
            Toast.threat(message, '', {
                onclick: (event) => this.router.navigateToRoute('threats', { id: notification.threatShortId }),
                timeOut: 0,
                extendedTimeOut: 0
            });

            // Threat detected with urgency
        } else if (notification.triggered_rule.includes('__CyberNotificationTopic__CompositeRule__alert__alert_created|absolute__$.urgency.value')) {
            // To find the urgency value within the notification's attributes, we will first need to find its key
            // Key will for example be 'absolute__$.urgency.value__>__33' for urgency rules (Note: different thresholds)
            const key = Object.keys(notification.attributes).find((k) => k.includes('absolute__$.urgency.value__'));
            const urgency = notification.attributes[key];

            const message = `New threat detected with urgency of ${Utilities.getUrgencyDisplay(urgency)}<br>
                             <small>onboard ${notification.vesselName || notification.attributes.vessel_name || 'N/A'} in the category ${notification.threatCategory}</small>`;
            Toast.threat(message, '', {
                onclick: (event) => this.router.navigateToRoute('threats', { id: notification.threatShortId })
            });

            // Vessel Risk Increased
        } else if (notification.event_type === EventTypes.EntityStatistic && notification.title.includes('Vessel risk increased to')) {
            const message = `${notification.title}<br>
                             <small>onboard ${notification.vesselName || notification.attributes.vessel_name || 'N/A'}</small>`;
            Toast.vessel(message, '', {
                onclick: (event) => this.router.navigate(`vessels/${notification.attributes.site_id}`)
            });

            // Alert New Comment
        } else if (notification.event_type === EventTypes.ALERT && notification.triggered_rule.includes('__CyberNotificationTopic__EventRule__alert__alert_comment_created')) {
            const message = `New comment on threat`;
            Toast.comment(message, '', {
                onclick: (event) => this.router.navigateToRoute('threats', { id: notification.threatShortId })
            });

            // CaseBook Investigation status change
        } else if (notification.triggered_rule.includes('__CyberInvestigationTopic__ThresholdRule__absolute__$.Status__=__')) {
            const investigationStatus = notification.attributes.investigation.status;
            const message = `Investigation status changed to ${investigationStatus}`;
            Toast.investigation(message, '', {
                onclick: (event) => this.router.navigate(`cases/${notification.attributes.investigation.casebookid}`)
            });

            // CaseBook New Comment
        } else if (notification.event_type === EventTypes.CASE && notification.triggered_rule.includes('__CyberNotificationTopic__EventRule__alert__alert_comment_created')) {
            const caseTitle = notification.attributes.casebooktitle;
            const message = `New comment on case ${caseTitle}`;
            Toast.comment(message, '', {
                onclick: (event) => this.router.navigate(`cases/${notification.attributes.casebook.id}`)
            });

        } else if (notification.event_type === EventTypes.CASE && notification.triggered_rule.includes('__CyberNotificationTopic__EventRule__sic_case__case_created')) {
            const caseTitle = notification.attributes.casebooktitle;
            const message = `New case created ${caseTitle}`;
            Toast.comment(message, '', {
                onclick: (event) => this.router.navigate(`cases/${notification.attributes.casebook.id}`)
            });

        } else
            // Portal does not support the notification yet, so this one should be implemented.
            // tslint:disable-next-line: no-console
            console.warn('Notification is not supported.', notification);
    }

    private async attached(): Promise<void> {
        this.connection = new signalr.HubConnectionBuilder()
            .withUrl(`${this.settings.get('credentials.cyberApiEndpoint')}/notificationshub`)
            .build();

        await this.listenForGenericNotifications();
        await this.listenForCyberNotifications();

        try {
            // intialize the signalr connection
            await this.connection.start();

            // after initialization, subscribe to company-specific notifications
            await this.subscribe();
        } catch (err) {
            console.warn(err.toString());
        }
    }

    private async detached(): Promise<void> {
        console.log('NotificationReceiver.detached() - closing connection');

        // unsubscribe to company-specific notifications
        await this.unsubscribe();

        // kill the connection
        this.connection.off(this.genericTopic);
        this.connection.stop();

        // TODO: If somehow the SignalR link is broken, update the icon!
    }

    private async listenForGenericNotifications(): Promise<void> {
        this.connection.on(this.genericTopic, (notification: any) => {
            console.log('Broadcasted global notification: ', notification);
        });
    }

    private async listenForCyberNotifications(): Promise<void> {
        this.connection.on(this.cyberNotificationTopic, (notification: EnrichedNotification) => {
            // short-circuit if the notification is not meant for the currently authenticated user
            if (notification.userId !== this.auth.profile.sub) return;

            // short-circuit if the notification is not meant for the company the user is signed-in to
            if (notification.companyId !== this.state.company()) return;

            this.showNotification(notification);

            // push out internal event
            this.ea.publish(EventKeys.onCyberNotificationReceived, notification);
        });
    }
}
