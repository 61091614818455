import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';

@autoinject()
export class Toggle {
    @bindable() private enabled = true;
    @bindable() private toggled = true;
    @bindable() private labelPosition: 'left' | 'right' = 'right';
    @bindable() private enabledBackgroundColour: string = '#06A';
    @bindable() private enabledBorderColour: string = '#06A';
    @bindable() private enabledPillColour: string = '#FFFFFF';
    @bindable() private disabledBackgroundColour: string = '#FFFFFF';
    @bindable() private disabledBorderColour: string = '#ADB5BD';
    @bindable() private disabledPillColour: string = '#ADB5BD';
    private element: HTMLElement;

    private attached(): void {
        this.setCssVariables();
    }

    private setCssVariables(): void {
        this.element.style.setProperty('--toggle-enabled-background-color', this.enabledBackgroundColour);
        this.element.style.setProperty('--toggle-enabled-border-color', this.enabledBorderColour);
        this.element.style.setProperty('--toggle-enabled-pill-color', this.enabledPillColour);
        this.element.style.setProperty('--toggle-disabled-background-color', this.disabledBackgroundColour);
        this.element.style.setProperty('--toggle-disabled-border-color', this.disabledBorderColour);
        this.element.style.setProperty('--toggle-disabled-pill-color', this.disabledPillColour);
    }
}
